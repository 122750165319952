import { getCategoriesFromAPICategories } from '@ifixit/analytics';
import { filterNullableItems, getProductVariantURI, parseMoney, type Money } from '@ifixit/helpers';
import { useIFixitApiClient } from '@ifixit/ifixit-api-client';
import { useCart, useShopifyStorefrontClient } from '@ifixit/shopify-cart-sdk';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { CrossSellProduct } from '../types';

type CrossSellApiResponse = {
   crossSellProducts: APICrossSellProduct[];
};

type APICrossSellProduct = {
   compare_at_price: number | null;
   handle: string;
   imageSrc: string | null;
   itemcode: string;
   marketing_heading: string | null;
   marketing_title: string | null;
   name: string;
   price_tiers: Record<string, number> | null;
   product_blurb: string | null;
   subPrice: number | null;
   url: string | null;
   variant_id: number;
   categoryAttributes: {
      is_tool: boolean;
      item_category: string;
      part_subcategory: string;
   };
   isPreorder: boolean;
   fulfiller: string;
};

export function useCrossSellItems() {
   const cartQuery = useCart();
   const cart = cartQuery.data;
   const client = useIFixitApiClient();
   const { storeCode, currencyCode } = useShopifyStorefrontClient();
   const { data: crossSellProducts } = useQuery({
      queryKey: ['cross-sell-products', storeCode, cart?.lineItems],
      keepPreviousData: true,
      queryFn: async (): Promise<CrossSellProduct[] | null> => {
         const params = new URLSearchParams({
            storeCode,
            skus: cart?.lineItems.map(item => item.itemcode).join(',') ?? '',
         });
         const result = await client.get<CrossSellApiResponse>(
            `cart/related_products/cross_sell?${params}`,
            'cross_sell'
         );
         return filterNullableItems(
            result.crossSellProducts.map(p => createCrossSellProduct(p, currencyCode))
         );
      },
   });
   const crossSellItems = useMemo(() => {
      if (cart == null || crossSellProducts == null) return [];
      return (
         crossSellProducts
            .filter(item => {
               const isAlreadyInCart =
                  item && cart.lineItems.find(lineItem => lineItem.itemcode === item.itemcode);
               if (isAlreadyInCart) return null;
               return item;
            })
            .sort((a, b) => a.handle.localeCompare(b.handle)) ?? []
      );
   }, [cart, crossSellProducts]);

   return crossSellItems;
}

function createCrossSellProduct(
   apiProduct: APICrossSellProduct,
   currencyCode: string
): CrossSellProduct | null {
   if (apiProduct.subPrice == null || apiProduct.url == null) {
      return null;
   }

   return {
      marketingHeading: apiProduct.marketing_heading,
      marketingTitle: apiProduct.marketing_title,
      marketingBlurb: apiProduct.product_blurb,
      itemcode: apiProduct.itemcode,
      shopifyVariantId: getProductVariantURI(apiProduct.variant_id),
      name: apiProduct.name,
      imageSrc: apiProduct.imageSrc,
      price: parseMoney(apiProduct.subPrice, currencyCode),
      compareAtPrice: apiProduct.compare_at_price
         ? parseMoney(apiProduct.compare_at_price, currencyCode)
         : null,
      proPricesByTier: apiProduct.price_tiers
         ? parsePriceTiers(apiProduct.price_tiers, currencyCode)
         : null,
      handle: apiProduct.handle,
      categories: getCategoriesFromAPICategories(apiProduct.categoryAttributes),
      url: apiProduct.url,
      isPreorder: apiProduct.isPreorder,
      fulfiller: apiProduct.fulfiller,
   };
}

const parsePriceTiers = (
   priceTiers: Record<string, number>,
   currencyCode: string
): Record<string, Money> =>
   Object.entries(priceTiers).reduce(
      (acc, [key, value]) => {
         acc[key] = {
            amount: value,
            currencyCode: currencyCode,
         };
         return acc;
      },
      {} as Record<string, Money>
   );
