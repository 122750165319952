import { Box, Center } from '@chakra-ui/react';
import { faImage } from '@fortawesome/pro-duotone-svg-icons';
import { FaIcon } from '@ifixit/icons';
import type { CartImageComponent } from '../types';

interface CartLineItemImageProps {
   ImageComponent: CartImageComponent;
   src?: string | null;
   alt?: string;
}

export function CartLineItemImage({ ImageComponent, src, alt }: CartLineItemImageProps) {
   return (
      <Box
         boxSize="64px"
         position="relative"
         borderWidth={1}
         borderRadius="md"
         overflow="hidden"
         flexShrink={0}
      >
         {src ? (
            <ImageComponent src={src} alt={alt} />
         ) : (
            <Center bgColor="gray.100" h="full">
               <FaIcon icon={faImage} color="gray.500" h="6" transition="color 300ms" />
            </Center>
         )}
      </Box>
   );
}
