import { useCallback } from 'react';
import { lessThan, Money } from '@ifixit/helpers';
import { usePriceTier } from '@ifixit/app/hooks';

export type UseUserPriceProps = {
   price: Money;
   compareAtPrice?: Money | null;
   proPricesByTier?: Record<string, Money> | null;
};

type UseUserPriceResult = {
   price: Money;
   compareAtPrice?: Money | null;
   isProPrice: boolean;
};

enum PriceTier {
   Employee = 'employee',
   Pro1 = 'pro_1',
   Pro2 = 'pro_2',
   Pro3 = 'pro_3',
   Pro4 = 'pro_4',
}

export function useUserPrice({
   storeCode,
   ...props
}: { storeCode: string } & UseUserPriceProps): UseUserPriceResult {
   const getUserPrice = useGetUserPrice({ storeCode });
   return getUserPrice(props);
}

export function useGetUserPrice({ storeCode }: { storeCode: string }) {
   const { priceTier, isProUser } = usePriceTier({ storeCode });

   return useCallback(
      ({ price, compareAtPrice, proPricesByTier }: UseUserPriceProps): UseUserPriceResult => {
         const proPrice = priceTier && proPricesByTier ? proPricesByTier[priceTier] || null : null;

         if (proPrice == null || lessThan(price, proPrice)) {
            return {
               price,
               compareAtPrice,
               isProPrice: false,
            };
         }
         return {
            price: proPrice,
            compareAtPrice: compareAtPrice ?? price,
            isProPrice: priceTier !== PriceTier.Employee,
         };
      },
      [priceTier, isProUser]
   );
}
