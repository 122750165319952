import { Button, Circle, Text, VStack } from '@chakra-ui/react';
import { faCartCircleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslations } from '@ifixit/i18n';
import { FaIcon } from '@ifixit/icons';

interface CartEmptyStateProps {
   onClose: () => void;
}

export function CartEmptyState({ onClose }: CartEmptyStateProps) {
   const t = useTranslations();

   return (
      <VStack spacing="5" p="5">
         <Circle size="72px" bg="brand.100">
            <FaIcon icon={faCartCircleExclamation} h="8" color="blue.ifixit" />
         </Circle>
         <Text>{t('CartPage.emptyCart')}</Text>
         <Button variant="cta" onClick={onClose} data-testid="back-to-shopping">
            {t('CartPage.backToShopping')}
         </Button>
      </VStack>
   );
}
