import {
   Alert,
   Box,
   Collapse,
   Flex,
   HStack,
   IconButton,
   Link,
   Text,
   VStack,
} from '@chakra-ui/react';
import {
   faCircleExclamation,
   faCircleMinus,
   faCirclePlus,
   faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { multiplyMoney } from '@ifixit/helpers';
import { FaIcon } from '@ifixit/icons';
import { useUpdateLineItemQuantity, type CartLineItem as LineItem } from '@ifixit/shopify-cart-sdk';
import type { ElementType } from 'react';
import { useEffect } from 'react';
import { ProductVariantPrice } from '../../commerce';
import type { CartImageComponent } from '../types';
import { CartLineItemImage } from './CartLineItemImage';

interface CartLineItemProps {
   ImageComponent: CartImageComponent;
   lineItem: LineItem;
   onRemove: (lineItem: LineItem) => void;
   removeDisabled: boolean;
   linkComponent?: ElementType;
   localeCode: string;
   ariaDecrease: string;
   ariaIncrease: string;
   notEnoughInventory: string;
   outOfStock: string;
   unableToUpdateQuantity: string;
}

export function CartLineItem({
   ImageComponent,
   lineItem,
   onRemove,
   removeDisabled,
   linkComponent,
   localeCode,
   ariaDecrease,
   ariaIncrease,
   notEnoughInventory,
   outOfStock,
   unableToUpdateQuantity,
}: CartLineItemProps) {
   const { updateLineItemQuantity, enabled: updateLineItemQuantityEnabled } =
      useUpdateLineItemQuantity();

   useEffect(() => {
      if (updateLineItemQuantity.isError) {
         const id = setTimeout(() => {
            updateLineItemQuantity.reset();
         }, 3000);
         return () => clearTimeout(id);
      }
   }, [updateLineItemQuantity.isError, updateLineItemQuantity.reset]);

   const incrementQuantity = () => {
      updateLineItemQuantity.mutate({ line: lineItem, quantityDelta: 1 });
   };

   const decrementQuantity = () => {
      updateLineItemQuantity.mutate({ line: lineItem, quantityDelta: -1 });
   };

   const nextFontSizeSm = '14px'; // Bypasses root font size difference between NextJS and Carpenter

   return (
      <Flex
         direction="column"
         w="full"
         p="3"
         bgColor="white"
         data-testid={`cart-drawer-line-item-${lineItem.itemcode}`}
      >
         <Flex w="full" justify="space-between" align="flex-start">
            <HStack spacing="3" align="flex-start">
               <CartLineItemImage
                  ImageComponent={ImageComponent}
                  src={lineItem.imageSrc}
                  alt={lineItem.name}
               />
               <Box>
                  <VStack align="flex-start" fontSize={nextFontSizeSm}>
                     <Flex direction="column">
                        <Link
                           as={linkComponent}
                           href={lineItem.url}
                           isExternal
                           fontWeight="semibold"
                           borderRadius="sm"
                           overflowWrap="break-word"
                        >
                           {lineItem.name}
                        </Link>
                        <Text color="gray.500" my="0">
                           {lineItem.itemcode}
                        </Text>
                        <Text color="gray.500" my="0">
                           {lineItem.variantTitle}
                        </Text>
                     </Flex>
                     <HStack>
                        <HStack borderWidth={1} borderRadius="md">
                           <IconButton
                              aria-label={ariaDecrease}
                              variant="ghost"
                              color="gray.500"
                              icon={<FaIcon icon={faCircleMinus} h="4" color="gray.400" />}
                              size="xs"
                              isDisabled={!updateLineItemQuantityEnabled || lineItem.quantity <= 1}
                              onClick={decrementQuantity}
                              data-testid="cart-drawer-decrease-quantity"
                           />
                           <Text
                              color="gray.800"
                              fontSize="12px"
                              my="0"
                              data-testid="cart-drawer-quantity"
                           >
                              {lineItem.quantity}
                           </Text>
                           <IconButton
                              aria-label={ariaIncrease}
                              variant="ghost"
                              color="gray.500"
                              icon={<FaIcon icon={faCirclePlus} h="4" color="gray.400" />}
                              size="xs"
                              isDisabled={
                                 !updateLineItemQuantityEnabled ||
                                 (lineItem.quantityAvailable != null &&
                                    lineItem.quantity >= lineItem.quantityAvailable)
                              }
                              onClick={incrementQuantity}
                              data-testid="cart-drawer-increase-quantity"
                           />
                        </HStack>
                        <InventoryTooLowMessage
                           item={lineItem}
                           notEnoughInventory={notEnoughInventory}
                           outOfStock={outOfStock}
                        />
                     </HStack>
                     <Collapse in={updateLineItemQuantity.isError} animateOpacity>
                        <Alert status="error" bg="transparent" textColor="red.500" py="0" px="1">
                           <FaIcon icon={faCircleExclamation} h="4" mr="1.5" color="red.500" />
                           {unableToUpdateQuantity}
                        </Alert>
                     </Collapse>
                  </VStack>
               </Box>
            </HStack>
            <Box>
               <IconButton
                  variant="ghost"
                  _hover={{ bg: 'gray.100', color: 'gray.400' }}
                  aria-label={`Remove ${lineItem.name} from cart`}
                  _active={{ bg: 'gray.200' }}
                  color="gray.300"
                  icon={<FaIcon icon={faTrash} h="4" />}
                  size="xs"
                  isDisabled={removeDisabled}
                  onClick={() => onRemove(lineItem)}
                  data-testid="cart-drawer-remove-item"
               />
            </Box>
         </Flex>
         <Box alignSelf="flex-end">
            <ProductVariantPrice
               price={multiplyMoney(lineItem.price, lineItem.quantity)}
               compareAtPrice={
                  lineItem.compareAtPrice == null
                     ? null
                     : multiplyMoney(lineItem.compareAtPrice, lineItem.quantity)
               }
               direction="column-reverse"
               size="small"
               localeCode={localeCode}
            />
         </Box>
      </Flex>
   );
}

function InventoryTooLowMessage({
   item,
   notEnoughInventory,
   outOfStock,
}: { item: LineItem; notEnoughInventory: string; outOfStock: string }) {
   if (item.quantityAvailable == null || item.quantity <= item.quantityAvailable) {
      return null;
   }
   const message = item.quantityAvailable < 1 ? outOfStock : notEnoughInventory;
   return (
      <Text fontSize="sm" textColor="red.500">
         {message}
      </Text>
   );
}
