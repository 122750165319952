import { ThemeOverride } from '@chakra-ui/react';
import {
   Alert,
   IconBadge,
   Pagination,
   breakpoints,
   colors,
   radii,
   shadows,
   sizes,
   space,
   zIndices,
} from '@ifixit/carpenter-theme';
import Badge from './components/badge';
import Button from './components/button';
import ProductPrice from './components/product-price';
import { styles } from './styles';

export const theme: ThemeOverride = {
   colors,
   breakpoints,
   radii,
   shadows,
   sizes,
   space,
   styles,
   zIndices,
   components: {
      Pagination,
      Badge,
      Alert,
      Button,
      IconBadge,
      ProductPrice,
   },
};
