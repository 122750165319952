'use client';

import { Box, BoxProps } from '@chakra-ui/react';
import type { MouseEvent, MouseEventHandler, PropsWithChildren } from 'react';
import { trackCustomEvent } from '@ifixit/analytics';
import { PrerenderedHTML } from '@ifixit/prerendered-html';

export interface RibbonBannerData {
   title: string;
   displayText: string;
}

export interface RibbonBannerProps {
   ribbon: RibbonBannerData | null;
   page: string;
   onClick?: MouseEventHandler;
}

export function RibbonBanner({
   ribbon,
   page,
   onClick,
   ...boxProps
}: PropsWithChildren<RibbonBannerProps> & BoxProps) {
   if (!ribbon) {
      return null;
   }

   return (
      <Box
         as="section"
         bg="brand.500"
         color="white"
         p={4}
         textAlign="center"
         className="piwikTrackContent"
         {...boxProps}
      >
         <PrerenderedHTML
            color="white"
            onClick={event => {
               trackRibbonBannerLinkClick(ribbon, page, event);
               onClick?.(event);
            }}
            sx={{
               p: { marginY: 0 },
               strong: { color: 'inherit' },
               a: { color: 'inherit', textDecoration: 'underline' },
            }}
            html={ribbon.displayText}
            data-content-name="Ribbon Banner"
            data-content-piece={`Ad: ${ribbon.title}`}
         />
      </Box>
   );
}

function trackRibbonBannerLinkClick(
   ribbon: RibbonBannerData,
   page: string,
   event: MouseEvent
): void {
   const element = event.target;
   if (element instanceof HTMLAnchorElement && element.href) {
      trackCustomEvent({
         eventCategory: 'Ribbon Banner',
         eventAction: `${ribbon.title} - Link - Clicked`,
         eventName: page,
      });
   }
}
