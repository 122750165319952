import { keyframes } from '@chakra-ui/react';
import type { Keyframes } from '@emotion/react';

export const slideIn: Keyframes = keyframes`
   from {
      height: 0;
   }
   to {
      height: var(--slide-height);
   }
`;

export const slideOut: Keyframes = keyframes`
   from {
      height: var(--slide-height);
   }
   to {
      height: 0;
   }
`;

export const fadeIn: Keyframes = keyframes`
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
`;

export const fadeOut: Keyframes = keyframes`
   from {
      opacity: 1;
   }
   to {
      opacity: 0;
   }
`;

export const slideFadeIn: Keyframes = keyframes`
   from {
      height: 0;
      opacity: 0;
   }
   to {
      height: var(--slide-height);
      opacity: 1;
   }
`;

export const slideFadeOut: Keyframes = keyframes`
   from {
      height: var(--slide-height);
      opacity: 1;
   }
   to {
      height: 0;
      opacity: 0;
   }
`;
