import { Alert, AlertDescription, Flex, Progress, Text } from '@chakra-ui/react';
import { formatMoney, moneyToNumber } from '@ifixit/helpers';
import { Cart } from '@ifixit/shopify-cart-sdk';
import { FC } from 'react';

export type CartPurchaseBannerProps = {
   cart: Cart;
   cartQualifies: boolean;
   cartTotalThreshold: number;
   localeCode: string;
   bannerText: BannerTextComponent;
};

export type BannerTextComponent = FC<{ amountLeft: string; percent: number }>;
export function CartPurchaseBanner({
   cart,
   cartQualifies,
   cartTotalThreshold,
   localeCode,
   bannerText: BannerText,
}: CartPurchaseBannerProps) {
   const cartTotal = cart?.totals.price;
   const currentAmount = moneyToNumber(cartTotal);
   const amountLeft = cartTotalThreshold - currentAmount;

   const amountLeftAsStr = formatMoney(
      {
         amount: amountLeft,
         currencyCode: cartTotal.currencyCode,
      },
      localeCode
   );

   const noDecimalThreshold = formatMoney(
      {
         amount: cartTotalThreshold,
         currencyCode: cartTotal.currencyCode,
      },
      localeCode,
      {
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }
   );

   const percent = (currentAmount / cartTotalThreshold) * 100;

   return (
      <Alert
         status="info"
         borderColor="brand.200"
         bg="brand.100"
         display="flex"
         flexDirection="column"
         alignItems="flex-start"
         gap={2.5}
      >
         <AlertDescription color="brand.500" fontSize="sm">
            <BannerText amountLeft={amountLeftAsStr} percent={percent} />
         </AlertDescription>
         {cartQualifies && (
            <Flex width="100%" alignItems="center" gap={2.5}>
               <Progress
                  width="100%"
                  bg="brand.200"
                  value={percent}
                  size="xs"
                  colorScheme="brand"
                  borderRadius="full"
                  flexGrow={1}
               />
               <Text color="brand.500" fontWeight="semibold" fontSize="sm">
                  {noDecimalThreshold}
               </Text>
            </Flex>
         )}
      </Alert>
   );
}
