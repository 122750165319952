import { createContext, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoint } from '@ifixit/primitives';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

const BreakpointsContext = createContext();

// See: https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
function shouldForwardProp(propName, target) {
   if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
   }

   return true;
}

function withBreakpointsContext(Component, custom_queries = {}) {
   // eslint-disable-next-line react/display-name -- FIXME: Component definition is missing display name
   return props => {
      const isDesktop = useMediaQuery({ minWidth: breakpoint.lg });
      const isTabletDesktop = useMediaQuery({ minWidth: breakpoint.md });
      const custom_breakpoints = Object.entries(custom_queries).reduce((result, [key, val]) => {
         const custom_breakpoint = useMediaQuery(val);
         result[key] = () => custom_breakpoint;
         return result;
      }, {});

      const breakpoints = {
         isDesktop: () => isDesktop,
         isTabletDesktop: () => isTabletDesktop,
         isTablet: () => isTabletDesktop && !isDesktop,
         isMobileTablet: () => !isDesktop,
         isMobile: () => !isTabletDesktop,
         ...custom_breakpoints,
      };

      return (
         <BreakpointsContext.Provider value={breakpoints}>
            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
               <Component {...props} />
            </StyleSheetManager>
         </BreakpointsContext.Provider>
      );
   };
}

function withBreakpoints(Component) {
   // eslint-disable-next-line react/display-name -- FIXME: Component definition is missing display name
   return props => {
      const breakpoints = useContext(BreakpointsContext);
      return (
         <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <Component breakpoints={breakpoints} {...props} />
         </StyleSheetManager>
      );
   };
}

export { withBreakpoints, withBreakpointsContext, BreakpointsContext };
