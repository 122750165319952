import { Box, BoxProps, Divider, VStack } from '@chakra-ui/react';
import { useRemoveLineItem, type Cart } from '@ifixit/shopify-cart-sdk';
import type { ElementType } from 'react';
import { CartLineItem } from './drawer/CartLineItem';
import type { CartImageComponent } from './types';
import { useTranslations } from '@ifixit/i18n';

export type CartLineItemsProps = BoxProps & {
   cart: Cart;
   testIdPrefix: string;
   ImageComponent: CartImageComponent;
   linkComponent?: ElementType;
   localeCode: string;
};

export function CartLineItems({
   cart,
   testIdPrefix,
   ImageComponent,
   linkComponent,
   localeCode,
   ...otherProps
}: CartLineItemsProps) {
   const t = useTranslations();
   const { removeLineItem, enabled: removeLineItemEnabled } = useRemoveLineItem();

   return (
      <Box data-testid={`${testIdPrefix}-line-items`} {...otherProps}>
         <VStack spacing="0" align="stretch" divider={<Divider />} listStyleType="none">
            {cart.lineItems.map(item => (
               <CartLineItem
                  key={item.itemcode}
                  lineItem={item}
                  onRemove={() => removeLineItem.mutate({ line: item })}
                  removeDisabled={!removeLineItemEnabled}
                  ImageComponent={ImageComponent}
                  linkComponent={linkComponent}
                  localeCode={localeCode}
                  ariaDecrease={t('CartPage.ariaDecrease')}
                  ariaIncrease={t('CartPage.ariaIncrease')}
                  notEnoughInventory={t('ErrorHandling.notEnoughInventory')}
                  outOfStock={t('ErrorHandling.outOfStock')}
                  unableToUpdateQuantity={t('ErrorHandling.unableToUpdateQuantity')}
               />
            ))}
         </VStack>
      </Box>
   );
}
