import { store } from 'Shared/react-initialize';
import * as HeaderActions from 'Shared/Header/actions';
import Notifications from './notifications';
import { io } from 'socket.io-client';

export function initializeNotificationsService() {
   if (window.notificationService || !App.isLoggedIn) {
      return;
   }

   window.notificationService = new NotificationService();
   window.notificationService.initialize();
}

class NotificationService {
   constructor() {
      this._notifications = new Notifications(this._markRead, this._markAllRead);
      this._dispatch();
   }

   initialize() {
      this._notifications.initializeNotifications(App.initialNotifications, App.unreadCount);

      if (App.realtimeEnabled) {
         this._connect();
      }
   }

   _connect() {
      const socket = io(App.realtimeHost, {
         // Try websocket first, since most users will be fine with it.
         transports: ['websocket', 'polling'],
      });

      // After connecting, subscribe to the room specific to the current user.
      socket.on('connect', () => {
         socket.emit('subscribe', { room: App.socketioRoom });
      });

      socket.on('notification', this._handleRealtimePayloads);
   }

   _handleRealtimePayloads = payload => {
      switch (payload.event) {
         case 'notification': {
            this._addNewNotification(payload);
            break;
         }
         case 'markRead': {
            payload.notificationSigs.forEach(this._notifications.setNotificationAsRead);
            this._dispatch();
            break;
         }
         case 'markAllRead': {
            this._dispatchMarkAllRead();
            break;
         }
      }
   };

   _dispatch() {
      this._notifications = this._notifications.clone();
      store.dispatch(HeaderActions.updateNotifications(this._notifications));
   }

   _dispatchMarkAllRead() {
      this._notifications.setAllNotificationsAsRead();
      this._dispatch();
   }

   _addNewNotification = notification => {
      this._notifications.addNewNotification(notification);
      this._dispatch();
   };

   _markRead = notification => {
      notification.setRead();
      this._dispatch();

      // Hit the acknowledge notification endpoint.
      new Request.API_2_0('notifications?ids=' + notification.signature, {
         method: 'GET',
      }).send();
   };

   _markAllRead = () => {
      this._dispatchMarkAllRead();

      new Request.API_2_0('notifications/all', {
         method: 'DELETE',
      }).send();
   };
}
