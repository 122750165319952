import type {
   MouseEventHandler,
   KeyboardEventHandler,
   ReactNode,
   ComponentType,
   ComponentProps,
} from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { shadow } from '@ifixit/primitives';

const LightTippy = styled(Tippy)`
   min-width: 200px;
   color: black;
   box-shadow: ${shadow[3]};
   padding: 0;
   border-radius: 8px;
   overflow: auto;
   background-color: white;

   .tippy-content {
      display: flex;

      > div {
         flex: 1 1 auto;
      }
   }
`;

/* eslint-disable-next-line @typescript-eslint/no-empty-function */
const noop = () => {};

interface WrappedProps {
   id: string;
   href: string;
   onClick: MouseEventHandler;
   onKeyDown: KeyboardEventHandler;
   children: ReactNode;
}

function withTippy<ClickableElementProps>(
   ClickableElement: ComponentType<WrappedProps & ClickableElementProps>
) {
   type ResultProps = {
      children: ReactNode;
      id?: string;
      clickableElementProps?: ClickableElementProps;
   } & Omit<ComponentProps<typeof Tippy>, 'children' | 'id'>;
   // eslint-disable-next-line react/display-name -- FIXME: Component definition is missing display name
   return ({
      onMount,
      content,
      children,
      id,
      clickableElementProps,
      ...tippyOptions
   }: ResultProps) => (
      <LightTippy
         trigger="click"
         duration={200}
         interactive
         placement="bottom-end"
         popperOptions={{ modifiers: [{ name: 'flip', enabled: false }] }}
         onMount={onMount || noop}
         content={content}
         appendTo="parent"
         {...tippyOptions}
      >
         {/* @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; id: string | undefine... Remove this comment to see the full error message */}
         <ClickableElement
            {...clickableElementProps}
            id={id}
            href="#"
            onClick={e => e.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
         >
            {children}
         </ClickableElement>
      </LightTippy>
   );
}

export default withTippy;
